@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}



:root {
  --bodyColor: #fff;
  --primaryColor: #eb3f2e;
  --secondaryColor: #eb3f2e;
  --navColr: #cdcdcd;
  --primarytext: #222;
  --darkGold: #de7501;
  --secondarytext: #d0d0d0;
  --gradiantBtn: #eb3f2e;
  --gradiantBtnHover: #df3625;
  /* --primaryBg: #ffe8e8; */
  --primaryBg: #f9f9f9;

}

.text_primary {
  color: var(--primaryColor);
}

.primaryBg {
  background: var(--primaryBg);
  margin-bottom: 20px;
  position: relative;
}

.primaryBg:before {
  content: "";
  background: url("../landing/images/bannerBg.jpg");
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-size: cover; */
  opacity: .04;
  background-position: right bottom;
}

.goldText {
  background: #ebd87a;
  background: linear-gradient(to right,
      #ebd87a 0%,
      #ebd87a 30%,
      #c68d2d 60%,
      #c68d2d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.marquee-container {
  position: relative;
  width: 100%;
  /* Adjust to fit your needs */
}

.tobeinvestBox {
  display: block;
  margin-bottom: 10px;
  /* Space between marquees */
}

.limitBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0 15px;
  font-size: 13px;
}

.textLink {
  color: #ad8a21;
}

.radialbg1 {
  background: var(--primaryColor);
  background: radial-gradient(circle,
      var(--primaryColor) 0,
      var(--secondaryColor) 100%);
  width: 350px;
  height: 350px;
  border-radius: 50%;
  opacity: 0.45;
  filter: blur(120px);
  position: absolute;
  top: 0;
  left: 0;
}

.radialbg2 {
  background: var(--primaryColor);
  background: radial-gradient(circle,
      var(--primaryColor) 0,
      var(--secondaryColor) 100%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  opacity: 0.45;
  filter: blur(60px);
  position: absolute;
  bottom: 0;
  right: 0;
}

.w-200 {
  width: 200px;
  display: block;
  margin: 20px auto;
}

.landingBody {
  background-color: var(--bodyColor);
  font-family: var(--primaryFont);
  color: var(--primarytext);
  font-size: 16px;
  scroll-behavior: smooth;
  line-height: 1.8;
}

.landingContainer {
  max-width: 1140px;
  width: 96%;
  margin: auto;
}

.navSection {
  display: flex;
  align-items: center;
  padding: 25px 0;
  position: relative;
}

.logoSection {
  width: 65px;
}

.clearboth {
  min-height: 1px;
  width: 100%;
  clear: both;
}

.signupSection {
  width: calc(100% - 65px);
  display: flex;
  justify-content: end;
  align-items: center;
}

.login_button {
  padding: 10px 26px;
  border-radius: 30px;
  background: var(--primaryColor);
  background: var(--gradiantBtn);
  color: #fff;
  text-decoration: none;
  margin-right: 5px;
  transition: 0.4s;
  border: 1px solid var(--primaryColor);
}

.login_button:hover,
.signup_button:hover {
  background: var(--gradiantBtnHover);
  border: 1px solid var(--primaryColor);
  color: #fff;
}

.banner_button {
  padding: 15px 26px;
  border-radius: 30px;
  background: var(--primaryColor);
  background: var(--gradiantBtn);
  color: #fff;
  text-decoration: none;
  margin-right: 5px;
  transition: 0.4s;
  /* border: 1px solid var(--primaryColor); */
  float: left;
}

.banner_button:hover {
  background: var(--gradiantBtnHover);
  background: var(--gradiantBtnHover);
}

.signup_button {
  padding: 10px 26px;
  border-radius: 30px;
  border: 1px solid #222;
  color: #222;
  text-decoration: none;
  transition: 0.4s;
}

.bannerSection {
  display: flex;
  flex-wrap: wrap;
  padding: 100px 0 150px;
  position: relative;
  z-index: 1;
}

.prImgHolder {
  background-color: #e5e5e5;
  padding: 20px;
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.prImgHolder:before {
  content: "";
  background-color: #f9f9f9;
  border: 5px solid var(--secondaryColor);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
  top: -20px;
  border-radius: 6px;
  transition: .4s;
}

.prBox:hover .prImgHolder:before {
  left: 0px;
  top: 0px;
  border-radius: 6px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.landingBody .slider-container {
  position: relative;
  width: 440px;
  height: 440px;
  overflow: hidden;
}

.landingBody .slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: scale(.6);
}

.landingBody .slider-image.active {
  opacity: 1;
  transform: scale(1);
}

.w_full {
  width: 100%;
}

.w_70 {
  width: 70%;
}

.w_50 {
  width: 50%;
}
.videoSize
{
  width: 540px;
  height: 410px;
}
.w_videoLeft {
  width: calc(100% - 570px);
  margin-right: 30px;
}
.w_videoRight {
  width: 540px;
}

.w_33 {
  width: 33.3%;
}

.w_30 {
  width: 30%;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_15 {
  padding-bottom: 15px;
}

.pb_20 {
  padding-bottom: 20px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pr_10 {
  padding-right: 10px;
}

.pr_15 {
  padding-right: 15px;
}

.pr_20 {
  padding-right: 20px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_5 {
  padding-left: 15px;
}

.pl_10 {
  padding-left: 15px;
}

.pl_15 {
  padding-left: 15px;
}

.pl_20 {
  padding-left: 20px;
}

.pl_30 {
  padding-left: 30px;
}

.pr_30 {
  padding-right: 30px;
}

.pr_50 {
  padding-right: 30px;
}

.pb_50 {
  padding-bottom: 50px;
}

.pl_85 {
  padding-left: 85px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}
.mt_5
{
  margin-top: 5px;
}
.mt_50 {
  margin-top: 50px;
}

.mr_7 {
  margin-right: 7px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 20px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.floatLeft {
  float: left;
}

.font-light {
  font-weight: 300;
}

.w_40 {
  width: 40%;
}

.w_50 {
  width: 50%;
}

.w_60 {
  width: 60%;
}

.w_35 {
  width: 35%;
}

.w_65 {
  width: 65%;
}

.uppercase {
  text-transform: uppercase;
}

.bannerTitle {
  font-size: 35px;
  color: var(--primarytext);
  line-height: 1.2;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.smalltitle {
  font-size: 14px;
  position: relative;
  padding-left: 45px;
}

.smalltitle:before {
  content: "";
  position: absolute;
  width: 36px;
  height: 1px;
  background-color: #222;
  margin-left: -45px;
  top: 13px;
}

.bannerPara {
  font-size: 16px;
}

.bannerRight {
  display: flex;
  justify-content: end;
  align-items: flex-start;
  margin-top: 15px;
}

.about2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 200px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

.items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.absolute1 {
  position: absolute;
  right: 40px;
  background-color: #f8e9cb;
  padding: 8px 15px;
  border-radius: 13px;
  border: 1px solid #f8d492;
  font-size: 16px;
  display: flex;
  align-items: center;
  top: -80px;
  backdrop-filter: blur(25px);
}

.absolute2 {
  position: absolute;
  left: 55px;
  background-color: #ffc2bc;
  padding: 21px 15px;
  border-radius: 20px;
  border: 1px solid #eb3f2e;
  font-size: 16px;
  top: 350px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(10px);
  width: 220px;
}

.absolute4 {
  position: absolute;
  right: -45px;
  background-color: #5959593b;
  padding: 8px 15px;
  border-radius: 13px;
  border: 1px solid #373a3d;
  font-size: 16px;
  display: flex;
  align-items: center;
  top: 30px;
  backdrop-filter: blur(25px);
}

.absolute5 {
  position: absolute;
  right: 35px;
  background-color: #5959593b;
  padding: 14px 20px;
  border-radius: 13px;
  border: 1px solid #373a3d;
  font-size: 16px;
  bottom: 50px;
  backdrop-filter: blur(25px);
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 36px;
  line-height: 1.2;
}

.text-2xl {
  font-size: 30px;
  line-height: 1.2;
}

.text-lg {
  font-size: 20px;
}

.text-xl {
  font-size: 19px;
}
.text_12 {
  font-size: 13px;
  line-height: 1.2;
}
.font-800 {
  font-weight: 800;
}

.absolute3 {
  position: absolute;
  right: 15px;
  font-size: 16px;
  bottom: 120px;
  width: 220px;
  display: flex;
}

.relative {
  position: relative;
}

.tobeinvestmainBox {
  background-color: #5959593b;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #373a3d;
  -webkit-backdrop-filter: blur(25px);
  line-height: 1.2;
}

.tobeinvestBox {
  font-size: 15px;
  display: flex;
}

.coinsNameBoxRrd {
  color: #df1800;
  font-weight: 600;
  font-size: 17px;
  display: inline;
  margin-right: 60%;
}

.coinsNameBox {
  background-image: linear-gradient(-225deg,
      #fff 0,
      #fff 29%,
      var(--primaryColor) 20%,
      #fff 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  animation: 10s linear infinite textclip;
  color: transparent;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.bgfad1 {
  background-color: rgb(45 42 42 / 23%);
  padding: 21px 15px;
  border-radius: 14px;
  border: 1px solid #373a3d;
  font-size: 16px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(10px);
  width: 110px;
  margin-right: 15px;
}

.bgfad2 {
  background-color: rgb(45 42 42 / 23%);
  padding: 21px 15px;
  border-radius: 14px;
  border: 1px solid #373a3d;
  font-size: 16px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(10px);
  width: 110px;
  margin-right: 15px;
}

.bgfad3 {
  background-color: rgb(45 42 42 / 23%);
  padding: 21px 15px;
  border-radius: 14px;
  border: 1px solid #373a3d;
  font-size: 16px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(10px);
  width: 110px;
  margin-right: 15px;
}

.bgfad4 {
  background-color: rgb(45 42 42 / 23%);
  padding: 21px 15px;
  border-radius: 14px;
  border: 1px solid #373a3d;
  font-size: 16px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(10px);
  width: 110px;
  margin-right: 15px;
}

.bannerImgWidth {
  width: 400px;
  max-width: 100%;
}

.section {
  padding: 70px 0;
}

.anmiation1 {
  animation: scale_up_left 7s ease-in-out infinite alternate both;
}

@keyframes scale_up_left {
  0% {
    -webkit-transform: scale(0.95) translateX(10px);
    transform: scale(0.95) translateX(10px);
  }

  100% {
    -webkit-transform: scale(1) translateX(30px);
    transform: scale(1) translateX(30px);
  }
}

.anmiation2 {
  animation: scale_up_down 7s ease-in-out infinite alternate both;
}

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.95) translateY(20px);
    transform: scale(0.95) translateY(20px);
  }

  100% {
    -webkit-transform: scale(1) translateX(-50px);
    transform: scale(1) translateX(-50px);
  }
}

.anmiation3 {
  animation: scale_up_down_left 7s ease-in-out infinite alternate both;
}

@keyframes scale_up_down_left {
  0% {
    -webkit-transform: scale(0.95) translatex(20px) translateY(20px);
    transform: scale(0.95) translateY(20px) translatex(20px);
  }

  100% {
    -webkit-transform: scale(1) translateX(50px) translateY(30px);
    transform: scale(1) translateX(50px) translateY(30px);
  }
}

#forexFeed {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.forexRate {
  display: inline-block;
  padding: 0 20px;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.productList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  width: 100%;
}

.productList li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 30px);
  margin: 15px;
  list-style: none;
  border-radius: 6px;
  overflow: hidden;
  background-color: #e5e5e5;
  padding: 20px;
  transition: .4s;
}

.productList li:hover {
  border: 1px solid var(--secondaryColor);
}

.productList li p {
  font-size: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 800;
  padding: 0 8px;
  text-align: center;
  line-height: 1.2;
  margin-top: 15px;
  text-transform: uppercase;
}

.productList li img {
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  border-radius: 6px;

}

.productList li:hover img {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
}

.aiBox {
  background-color: #f9f9f9;
  padding: 40px 25px;
  border-radius: 20px;
  font-size: 15px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: 100%;
}

.aiBox_firstchild {
  padding-right: 15px;
}

.aiBox_secondtchild {
  padding-left: 8px;
  padding-right: 8px;
}

.aiBox_thirdchild {
  padding-left: 15px;
}

.bgcolor1 {
  background-color: #f9f9f9;
  padding: 50px 40px;
  border-radius: 20px;
  font-size: 15px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: 100%;
}

.footer {
  background-color: #f1f1f1;
  padding: 60px 15px 40px;
  margin-top: 40px;
}

.footerlogoSection {
  display: flex;
  justify-content: center;

}

.footerlogoSection img {
  width: 80px;
}

.font_15 {
  font-size: 15px;
}

.font_13 {
  font-size: 13px;
}

.socialMedia {
  width: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 15px auto;
}

.socialMedia li {
  background-color: #5959593b;
  padding: 7px 7px;
  border-radius: 8px;
  border: 1px solid #373a3d;
  list-style: none;
}

.telegrameCommunity {
  /* background-color: var(--primaryColor); */
  /* background-image: url("../landing-page/images/telegramBg.webp"); */
  padding: 40px 35px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-align: center;
  position: relative;
}

.telegrameCommunity::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #010100;
  opacity: 0.6;
  z-index: 0;
}
.postDesigns
{
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  width: calc(100% + 20px);
}
.postDesigns li
{
  width: calc(100% / 3 - 20px);
  margin: 10px;
  list-style: none;
}

.z2 {
  z-index: 2;
}

.telegrame_button {
  padding: 11px 6px 11px 25px;
  border-radius: 30px;
  background: #0b0b09;
  background: var(--gradiantBtn);
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
  width: 157px;
  margin: auto;
}

.telegrame_button:hover {
  background-color: #0b0b09;
}

.plan_income_tableHolder {
  overflow-x: auto;
  width: 100%;
  margin: 40px auto;
}

.plan_income_table {
  border-radius: 10px;
  min-width: 400px;
  margin: auto;
  background-color: #5959593b;
  padding: 40px 35px;
  border-radius: 20px;
  border: 1px solid #373a3d;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.plan_income_table th {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.plan_income_table th {
  background-color: #d19933;
}

.spanHighlight {
  background-color: #eb3535;
  border-radius: 4px;
  padding: 7px 10px;
  color: #fff;
}

.goldAnimate {
  position: absolute;
  top: -24px;
  z-index: -1;
  width: 75px;
}

.faq-accordion {
  width: 100%;
}

.faq-item {
  margin-bottom: 10px;
  border: 1px solid #373a3d;
  border-radius: 6px;
}

.faq-question {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #5959593b;
  border: 1px solid #373a3d;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 10px;
}

.faq-answer.active {
  max-height: 200px;
  padding: 20px 20px;
}

.conpensationPlan {
  margin: 20px auto 40px;
  display: flex;
  flex-wrap: wrap;
  max-width: 480px;
}

.conpensationPlan li {
  width: 42%;
  margin: 10px 4%;
  background-color: #5959593b;
  border-radius: 20px;
  border: 1px solid #373a3d;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 4px;
  padding: 7px 10px;
  list-style: none;
  color: #fff;
  padding-left: 25px;
  position: relative;
  letter-spacing: 0.3px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}

.conpensationPlan li::before {
  content: "";
  /* background: url("../landing-page/images/dollar-symbol.webp"); */
  position: absolute;
  left: -20px;
  top: 2px;
  background-size: 100% 100%;
  width: 31px;
  height: 31px;
}

.achievmnt {
  margin-top: 15px;
}

.achievmnt ul {
  display: flex;
  flex-wrap: wrap;
}

.achievmnt ul li {
  width: calc(100% / 3 - 20px);
  margin: 10px 10px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #5959593b;
  border: 1px solid #222427;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  list-style: none;
}

.rwardText {
  font-size: 24px;
  background: #ebd87a;
  background: linear-gradient(to right,
      #ebd87a 0%,
      #ebd87a 30%,
      #c68d2d 60%,
      #c68d2d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  border-bottom: 1px solid #222427;
  padding: 15px;
}

.criteriaText {
  text-transform: uppercase;
  color: #fff;
  padding: 15px 0 5px;
}

.criterias {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5px 0 15px;
}

.prfitShareUl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: start;
}

.prfitShareUl li {
  width: calc(100% / 3 - 20px);
  margin: 10px 0px 10px 20px;
  list-style: none;
  background-color: #5959593b;
  padding: 30px 25px;
  border-radius: 10px;
  border: 1px solid #373a3d;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: 100%;
}

.profitText {
  font-size: 34px;
  background: #ebd87a;
  background: linear-gradient(to right,
      #ebd87a 0%,
      #ebd87a 30%,
      #c68d2d 60%,
      #c68d2d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.notAchieved {
  font-size: 34px;
  color: #b5b5b5;
  font-weight: 600;
}

.shareText {
  padding-top: 30px;
  text-align: right;
}

.pl_60 {
  padding-left: 60px;
}

.pr_60 {
  padding-right: 60px;
}

.font_12 {
  font-size: 12px;
  line-height: 1.3;
  display: inline-block;
}

.flex {
  display: flex;
}

.text_button {
  border-radius: 19px;
  color: #fbfbfb;
  margin-right: 5px;
  padding: 4px 13px;
  text-decoration: none;
  border: 1px solid #fff;
  cursor: pointer;
}

.text_button:hover {
  color: #c79338;
  border: 1px solid #c79338;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.popup-content {
  background-color: #393939;
  padding: 25px 10px;
  border-radius: 5px;
  width: 92%;
  text-align: center;
  max-width: 540px;
  position: relative;
}

.close {
  position: absolute;
  top: -15px;
  right: -10px;
  cursor: pointer;
  font-size: 29px;
  background-color: #ad2a11;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupregisterBox {
  padding-top: 35px;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.text_button2 {
  cursor: pointer;

  padding: 10px 16px;
  border-radius: 6px;
  background: var(--gradiantBtn);
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
  border: 1px solid var(--primaryColor);
}

.text_button2:hover {
  border: 1px solid var(--primaryColor);
  background: var(--primaryColor);
}

.popupHeading {
  font-size: 21px;
  padding-bottom: 15px;
  color: #fff;
  border-bottom: 1px solid #555252;
}

.popupsubHeading {
  font-size: 16px;
  margin-bottom: 10px;
  padding-top: 20px;
  color: #fff;
}

.popupSubCode {
  padding-bottom: 20px;
}

.partnershipcodeBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.partnershipcode {
  border-radius: 4px;
  color: #3c91ed;
  margin-right: 5px;
  padding: 5px 15px;
  text-decoration: none;
  border: 1px solid #3c91ed;
}

.copyButton {
  border-radius: 4px;
  color: #fbfbfb;
  padding: 5px 8px;
  text-decoration: none;
  border: 1px solid var(--primaryColor);
  cursor: copy;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  background-color: #c79438;
}

.partnershipcodeBox {
  position: relative;
}

.partnershipcode {
  font-size: 15px;
}

.copyButton {
  cursor: pointer;
}

.copyMessage {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  /* Green background for success */
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 8px;
  opacity: 0;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.pollList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pollList li {
  width: calc(100% / 3 - 20px);
  margin: 10px 10px;
  padding: 2px;
  text-align: center;
  border-radius: 10px;
  background-color: #5959593b;
  border: 1px solid #222427;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  list-style: none;
}

.pollList li .banner_button {
  padding: 11px 24px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.polls .swiper {
  padding-left: 50px;
  padding-right: 40px;
}

.polls .swiper-button-prev:after,
.polls .swiper-button-next:after {
  font-size: 13px !important;
}

.polls .swiper-button-next,
.polls .swiper-button-prev {
  background-color: #494941;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px !important;
  display: block;
  color: #fff;
}

.polls .swiper-wrapper {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
}

.polls .swiper-slide {
  background-color: #5959593b;
  border: 1px solid #222427;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  text-align: center;
}

.polls .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 0px);
  left: auto;
}

.polls .swiper-button-prev.swiper-button-disabled,
.polls .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

.borderRadius16 {
  border-radius: 14px;
}

.w-full {
  width: 100%;
}

.navTopBarContainer {
  background-color: rgb(45 42 42 / 23%);
  border-bottom: 1px solid #1e1e1e;
  padding: 10px 0;
}

.navTopBarContainerSub {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  font-size: 15px;
}

/* ************************************************************* */

@media (max-width: 993px) {

  .productList li {
    width: calc(100% / 2 - 15px);
    margin: 7.5px;
  }

  .pr_30 {
    padding-right: 0;
  }

  .pl_70 {
    padding-left: 0;
  }

  .pl_20 {
    padding-left: 0px;
  }

  .pr_20 {
    padding-right: 0px;
  }

  .bannerSection {
    flex-direction: column-reverse;
  }

  .bannerRight {
    /* max-width: 580px; */
    justify-content: flex-start;
    height: 500px;
    padding-left: 0 !important;
  }

  .w_50,
  .w_40,
  .w_60 {
    width: 100%;
  }

  .flex-col-reverse {
    flex-direction: column-reverse;
  }

  .radialbg1 {
    width: 100%;
  }

  .pl_85 {
    padding-left: 0;
    padding-top: 50px;
  }

  .w_40.pr_15 {
    padding-right: 0;
  }

  .w_40.pl_15 {
    padding-left: 0;
  }

  .aiPower {
    max-width: 440px;
    margin: auto;
  }

  .w_33 {
    width: 100%;
    max-width: 300px;
    margin: auto;
  }

  .aiBox_firstchild {
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .aiBox_secondtchild {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .aiBox_thirdchild {
    padding-left: 0px;
    margin-bottom: 20px;
  }

  .bgcolor1 {
    background-color: #5959593b;
    padding: 30px 25px;
    flex-direction: column-reverse;
  }

  .bgcolor1 .w_60.pr_15 {
    margin-top: 25px;
    padding-right: 0;
  }
  .w_videoLeft {
    width: calc(100% - 460px);
    margin-right: 20px;
  }
  .w_videoRight {
    width: 440px;
    margin: auto;
  }
  .videoSize {
    width: 460px;
    height: 355px;
}
}

/* ************************************************************* */
@media (max-width: 768px) {

  .postDesigns li
{
  width: calc(100% / 2 - 10px);
  margin: 5px;
}

  .bannerSection .w_50.pr_15 {
    padding-right: 0;
  }
  .w_videoLeft {
    width: calc(100% - 0px);
    margin-right: 0px;
  }
  .postDesigns {
    margin-left: -5px;
    width: calc(100% + 10px);
    margin-top: 5px;
}
  .w_videoRight {
    width: 360px;
    margin: auto;
  }
  .videoSize {
    width: 360px;
    height: 305px;
}
  .flex_col_reverse {
    flex-direction: column-reverse;
  }

  .absolute1 {
    top: -60px;
  }

  .w_70,
  .w_30 {
    width: 100%;
  }

  .prImgHolder:before {
    left: 5%;
    top: -30px;
    width: 90%;
  }

  .pl_30 {
    padding-left: 30px;
  }

  .pr_30 {
    padding-right: 0px;
  }

  .pl_30 {
    padding-left: 0px;
  }

  .banner_button {
    padding: 13px 17px;
    font-size: 15px;
  }

  .textLink.pl_5 {
    padding-left: 8px;
  }

  .absolute4 {
    top: -60px;
  }

  .absolute5 {
    bottom: -50px;
  }

  .text-3xl {
    font-size: 26px;
    line-height: 1.2;
  }

  .text-2xl {
    font-size: 23px;
    line-height: 1.2;
  }

  .section,
  .bannerSection {
    padding: 50px 0;
  }

  .pl_60 {
    padding-left: 0;
  }

  .pr_60 {
    padding-right: 0px;
  }

  .w_35,
  .w_65,
  .w_50,
  .w_50 {
    width: 100%;
  }

  .prfitShareUl li {
    width: calc(100% / 2 - 14px);
    margin: 10px 7px;
    padding: 30px 15px;
  }

  .achievmnt ul li {
    width: calc(100% / 2 - 20px);
  }

  .w_33 {
    max-width: 100%;
  }

  .absolute4 {
    right: 0;
  }

  .logoSection {
    width: 80px;
    padding-right: 7px;
  }

  .signupSection {
    width: calc(100% - 80px);
  }

  .bannerTitle {
    font-size: 42px;
  }

  .text_button2 {
    width: 100%;
  }

  .login_button {
    padding: 8px 19px;
    margin-right: 3px;
    font-size: 16px;
    margin-top: 0px;
  }

  .signup_button {
    padding: 8px 19px;
    font-size: 16px;
    margin-top: 0;
  }

  .radialbg1 {
    z-index: -1;
  }

  .absolute1 {
    left: 0;
  }

  .absolute2 {
    top: 300px;
    left: 0;
  }

  .tobeinvestmainBox {
    border-radius: 4px;
    position: absolute;
    top: -60px;
  }

  .absolute3 {
    display: none;
  }


  @keyframes scale_up_down_left {
    0% {
      -webkit-transform: scale(0.95) translatex(0px) translateY(0px);
      transform: scale(0.95) translateY(0px) translatex(0px);
    }

    100% {
      -webkit-transform: scale(1) translateX(20px) translatex(20px);
      transform: scale(1) translateX(20px) translatex(20px);
    }
  }
}

/* ************************************************************* */
@media (max-width: 568px) {

  .landingBody .slider-container {
    width: 360px;
    height: 360px;
  }

  .productList li {
    width: calc(100%);
    margin: 15px 0;

  }

  .pollList li {
    width: calc(100% - 0px);
    margin: 10px 0;
  }

  .achievmnt ul li {
    width: calc(100% - 0px);
    margin: 10px 0;
  }
}